

























import { Vue, Component, Prop } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Form, FormItem, Input } from "element-ui";
import signjs from "./index";
import { loginExpert } from "./index";

@Component({
    components: {
        "el-form": Form,
        "el-form-item": FormItem,
        "el-input": Input,
    },
})
export default class LoginForm extends Vue {
    @Action("getSetToken") setToken: any;
    @Getter("getToken") token: any;
    @Prop({ type: String, required: true, default: () => ({}) })
    childName!: string; //表单数据

    get emailLable() {
        return `${this.$t("sign.bestttname")}：`;
    }
    get pwdLable() {
        return `${this.$t("sign.pwd")}：`;
    }

    get emailPlaceholder() {
        console.log(123);
        return this.$t("sign.bestttnamePlaceholder");
    }
    get pwdPlaceholder() {
        return this.$t("sign.pwdPlaceholder");
    }

    agreementDialogVisible: boolean = false;
    loginres: any;

    //登录表单数据
    loginFormData = {
        bestttname: "",
        password: "",
    };

    //登录表单验证规则数据
    get loginFormRules() {
        let opt = {
            bestttname: [
                {
                    required: true,
                    message: this.$t("sign.login_bestttname_rule_notnull"),
                    trigger: "blur",
                },
            ],
            password: [
                {
                    required: true,
                    message: this.$t("sign.login_pwd_rule_notnull"),
                    trigger: "blur",
                },
            ],
        };
        return opt;
    }
    //提交表单
    sub(formName: string, callback?: any, isLoading?: any) {
        signjs.validateForm(formName, this.$refs).then((res: any) => {
            if (res) {
                isLoading();
                this.loginFormData.bestttname =
                    this.loginFormData.bestttname.replace(/\s*/g, "");
                this.loginFormData.password =
                    this.loginFormData.password.replace(/\s*/g, "");
                loginExpert(this.loginFormData).then((res: any) => {
                    this.$notify({
                        title: res.status === 0 ? "成功" : "失败",
                        message: res.msg,
                        type: res.status === 0 ? "success" : "error",
                    });
                    if (callback) {
                        callback(res.status, res.data);
                    } else {
                        console.error("Login:No Callback");
                    }
                });
            }
        });
    }

    //重置表单数据
    resetForm(formName: string) {
        //@ts-ignore
        signjs.resetForm(formName, this.$refs);
    }

    jump(type: any, target: any) {
        if (type == "link") {
            window.location.href = target;
        } else if (type == "route") {
            this.$router.push({ name: target, params: { step: "0" } });
        }
    }

    mounted() {}
}
