






















import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import {
    Card,
    Form,
    FormItem,
    Input,
    Radio,
    RadioGroup,
    RadioButton
} from "element-ui";

import LoginForm from "@/components/sign/LoginForm.vue";
@Component({
    components: {
        "el-card": Card,
        "el-form": Form,
        "el-form-item": FormItem,
        "el-input": Input,
        "el-radio-group": RadioGroup,
        "el-radio": Radio,
        "el-radio-button": RadioButton,
        LoginForm
    }
})
export default class Login extends Vue {
    loginFormName = "loginForm";
    childName: string = "loginForm";

    /* 提交 */
    sub() {
        this.$nextTick(() => {
            let loading: any;
            let isLoading = () => {
                loading = this.$loading({
                    lock: true,
                    text: "加载中",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                });
            };
            let closeLoading = () => {
                loading.close();
            };
            //调用子组件提交方法
            (this.$refs[this.loginFormName] as any).sub(
                this.childName,
                (status: any, data: any) => {
                    this.afterSub(status, data, closeLoading);
                },
                isLoading
            );
        });
    }

    /* 提交后 */
    afterSub(status: number, data: any, closeLoading: any) {
        if (status === 0) {
            // console.log(data);
            if (data.expert_id) {
                this.$router.push({name:"assessList",params:{eid:data.expert_id}})
            } else {
                this.$router.push({ name: "userinfo" });
            }

            closeLoading();
        } else {
            closeLoading();
        }
    }

    /* 清空 */
    reset() {
        this.$nextTick(() => {
            //调用子组件清空方法
            (this.$refs[this.loginFormName] as any).resetForm(this.childName);
        });
    }
}
